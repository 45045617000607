import { useEffect, useState } from "react";
import "./App.css";

function App() {
	const [spaceFact, setSpaceFact] = useState("");

	useEffect(() => {
		let removeHeadersRegex = /=+[\s\w+]+=+/g;
		let sentenceRegex = /[^.!?\s][^.!?]*(?:[.!?](?!['"]?\s|$)[^.!?]*)*[.!?]?['"]?(?=\s|$)/g;
		fetch(
			"https://en.wikipedia.org/w/api.php?action=query&prop=extracts&titles=Outer%20space&format=json&explaintext=&origin=*",
		)
			.then((response) => response.json())
			.then((data) => {
				let facts = data.query.pages["177602"].extract
					.replaceAll(removeHeadersRegex, "")
					.match(sentenceRegex);
				setSpaceFact(facts[Math.floor(Math.random() * facts.length)]);
			});
	}, []);
	console.log(spaceFact);
	return (
		<div className="grid justify-center w-screen h-screen bg-black">
			<div className="w-5/6 m-auto text-center">
				<p className="text-6xl text-white">{spaceFact}</p>
			</div>
		</div>
	);
}

export default App;
